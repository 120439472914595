import Icon from './Icon';

const VIEW_ALL = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2669 5.30277C14.7827 4.86971 14.0339 4.90548 13.5944 5.38266C13.155 5.85983 13.1913 6.59771 13.6755 7.03077C15.2942 8.47841 16.3253 9.51129 17.4164 10.8332L4.18402 10.8332C3.5301 10.8332 3 11.3556 3 12C3 12.6444 3.5301 13.1668 4.18402 13.1668L17.4164 13.1668C16.3253 14.4887 15.2942 15.5216 13.6755 16.9692C13.1913 17.4023 13.155 18.1402 13.5944 18.6173C14.0339 19.0945 14.7827 19.1303 15.2669 18.6972C17.6596 16.5575 18.8847 15.2228 20.7483 12.7192C20.9058 12.5211 21 12.2708 21 12C21 11.7292 20.9058 11.4788 20.7483 11.2807C18.8847 8.77717 17.6595 7.4425 15.2669 5.30277Z"
      fill="#860EE6"
    />
  </svg>
);

export default function ViewAll({className}) {
  return (
    <Icon className={className} label="ViewAll">
      {VIEW_ALL}
    </Icon>
  );
}

ViewAll.propTypes = {
  className: Icon.propTypes.className,
};

ViewAll.defaultProps = {
  className: '',
};
